'use client'

import { Container } from '@vinted/web-ui'

import { ErrorContent, StackTrace } from '@marketplace-web/shared/ui-helpers'
import { useSession } from '@marketplace-web/shared/session'
import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  error: Error & { digest?: string }
  reset: () => void
}

const ErrorPage = ({ error, reset }: Props) => {
  const translate = useTranslate('error_page')
  const isGod = useSession().user?.is_god

  return (
    <>
      <ErrorContent
        action={translate('actions.retry')}
        body={translate('client_error.body')}
        title={translate('client_error.title')}
        onActionClick={reset}
      />
      {isGod && (
        <Container>
          <StackTrace error={error} />
        </Container>
      )}
    </>
  )
}

export default ErrorPage
